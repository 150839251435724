import create from "zustand";

const useStore = create((set) => ({
  language: "",
  country: "",
  change: true,
  temporaryNotice: false,

  setLanguage: (language) => set({ language }),
  setCountry: (country) => set({ country }),
  setChange: (change) => set({ change }),
  setTemporaryNotice: (temporaryNotice) => set({ temporaryNotice }),
  resetLanguage: () => set({ language: "", country: "", change: false }),
}));

export default useStore;
