// import { Cookies } from "react-cookie";
import Cookies from "universal-cookie";
import axios from "axios";

import {
  LOGIN_THAILAND_URL,
  SIGNIN_URL,
  AUTO_LOGIN_URL,
  ADMIN_DISPLAY_SETUP_URL,
  SAVE_ELECTRIFIED_SETTING_URL,
  GET_TRANSLATION_ITEM_URL,
  EDIT_TRANSLATION_ITEM_URL,
  DISPLAY_TRANSLATION_URL,
  GET_DISPLAY_VEHICLE_URL,
  EDIT_DISPLAY_VEHICLE_URL,
  DISPLAY_TEMPLATE_URL,
  EDIT_TEMPLATE_URL,
  GET_APPID_LIST_URL,
  GET_DEALER_DATA_URL,
  GET_SUBSCRIBE_LOG_URL,
  GET_LATEST_RELEASE_URL,
  GET_VERSION_URL,
  SET_SUBSCRIPTION_URL,
  DELETE_SUBSCRIPTION_URL,
  GET_DASHBOARD_DATA_URL,
  GET_APP_ID_BY_DEALERSHIP_URL,
  EDIT_SUBSCRIBE_URL,
  GET_DEALERSHIP_COUNTRY_LIST_URL,
  GET_HME_DASHBOARD_DATA_URL,
} from "utils/constants";

import { getCookie } from "./cookie";

const cookies = new Cookies();

export const loginThailand = async (id, password) => {
  const body = {
    userId: id,
    password,
  };

  let result;

  await axios
    .post(LOGIN_THAILAND_URL, body)
    .then((res) => {
      const { name, dealerName, country, userType } = res.data;

      const maxAge = 21600;

      cookies.set("accessToken", res.data.access_token, {
        maxAge,
        path: "/",
      });
      cookies.set("userId", id, {
        maxAge,
        path: "/",
      });
      cookies.set("name", name, {
        maxAge,
        path: "/",
      });
      cookies.set("dealerName", dealerName, {
        maxAge,
        path: "/",
      });
      cookies.set("country", country, {
        maxAge,
        path: "/",
      });
      cookies.set("userType", userType, {
        maxAge,
        path: "/",
      });

      result = {
        result: true,
        dealerName: res.data.dealer_name,
        name,
        country,
        userType,
      };
    })
    .catch(() => {
      result = {
        result: false,
        dealerName: null,
        name: null,
        country: null,
        userType: null,
      };
    });

  return result;
};

export const accountValidate = async (id, password) => {
  const body = { id, password };

  let result;

  await axios
    .post(SIGNIN_URL, body)
    .then((res) => {
      const { name, dealerName, country, userType } = res.data;

      const maxAge = 21600;

      cookies.set("accessToken", res.data.access_token, {
        maxAge,
        path: "/",
      });
      cookies.set("userId", id, {
        maxAge,
        path: "/",
      });
      cookies.set("name", name, {
        maxAge,
        path: "/",
      });
      cookies.set("dealerName", dealerName, {
        maxAge,
        path: "/",
      });
      cookies.set("country", country, {
        maxAge,
        path: "/",
      });
      cookies.set("userType", userType, {
        maxAge,
        path: "/",
      });

      result = {
        result: true,
        dealerName: res.data.dealer_name,
        name,
        country,
        userType,
      };
    })
    .catch(() => {
      result = {
        result: false,
        dealerName: null,
        name: null,
        country: null,
        userType: null,
      };
    });

  return result;
};

// export const signin = async (id, password) => {
//   const body = { id, password };
//   let result;
//   await axios
//     .post(SIGNIN_URL, body)
//     .then((res) => {
//       const { name, dealerName, country, userType } = res.data;

//       const expires = new Date();
//       expires.setSeconds(expires.getSeconds() + 21600);

//       cookies.set("accessToken", res.data.access_token, {
//         expires,
//       });
//       cookies.set("userId", id, {
//         expires,
//       });
//       cookies.set("name", name, {
//         expires,
//       });
//       cookies.set("dealerName", dealerName, {
//         expires,
//       });
//       cookies.set("country", country, {
//         expires,
//       });
//       cookies.set("userType", userType, {
//         expires,
//       });

//       result = {
//         result: true,
//         dealerName: res.data.dealer_name,
//         name,
//         country,
//         userType,
//       };
//     })
//     .catch(() => {
//       result = {
//         result: false,
//         dealerName: null,
//         name: null,
//         country: null,
//         userType: null,
//       };
//     });
//   return result;
// };

export const autosignin = async (tokenValue) => {
  const body = { tokenValue };
  let result;
  await axios
    .post(AUTO_LOGIN_URL, body)
    .then((res) => {
      const { userId, name, dealerName, country, userType } = res.data;

      const maxAge = 21600;

      cookies.set("accessToken", res.data.access_token, {
        maxAge,
        path: "/",
      });
      cookies.set("userId", userId, {
        maxAge,
        path: "/",
      });
      cookies.set("name", name, {
        maxAge,
        path: "/",
      });
      cookies.set("dealerName", dealerName, {
        maxAge,
        path: "/",
      });
      cookies.set("country", country, {
        maxAge,
        path: "/",
      });
      cookies.set("userType", userType, {
        maxAge,
        path: "/",
      });

      result = {
        result: true,
        dealerName: name,
        name,
        country,
        userId,
        userType,
      };
    })
    .catch(() => {
      result = {
        result: false,
        dealerName: null,
        name: null,
        country: null,
        userType: null,
      };
    });
  return result;
};

export const displaySetup = async () => {
  let result;

  // const accessToken = cookies.get("accessToken");
  const accessToken = getCookie("accessToken");

  await axios
    .get(ADMIN_DISPLAY_SETUP_URL, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      result = {
        result: true,
        electrifiedAll: res.data.all_electrifies,
        electrified_version: res.data.electrified_version,
        displayableElectrifies: res.data.displayable_electrifies,
      };
    })
    .catch(() => {
      result = {
        result: false,
        electrifiedAll: null,
        electrified_version: null,
        displayableElectrifies: null,
      };
    });
  return result;
};

export const getDisplayVehicle = async (country) => {
  let result;

  const accessToken = getCookie("accessToken");
  const body = {
    country,
  };

  await axios
    .post(GET_DISPLAY_VEHICLE_URL, body, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      result = {
        result: true,
        data: res,
      };
    })
    .catch(() => {
      result = {
        result: false,
        data: null,
      };
    });

  return result;
};

export const editDisplayVehicle = async (body) => {
  let result;

  const accessToken = getCookie("accessToken");

  await axios
    .patch(EDIT_DISPLAY_VEHICLE_URL, body, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      result = {
        result: true,
        data: res,
      };
    })
    .catch(() => {
      result = {
        result: false,
        data: null,
      };
    });

  return result;
};

export const saveEelctrifiedSettting = async (
  displayableElectrifies,
  electrifiedVersion
) => {
  let result;

  const accessToken = getCookie("accessToken");
  const body = {
    displayable_electrifies: displayableElectrifies,
    electrified_version: electrifiedVersion,
  };

  await axios
    .patch(SAVE_ELECTRIFIED_SETTING_URL, body, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      result = {
        result: true,
        electrifiedAll: res.data.all_electrifies,
        electrified_version: res.data.electrified_version,
        displayableElectrifies: res.data.displayable_electrifies,
      };
    })
    .catch(() => {
      result = {
        result: false,
        electrifiedAll: null,
        electrified_version: null,
        displayableElectrifies: null,
      };
    });
  return result;
};

export const getTranslations = async (country, appType, group, language) => {
  let result;
  const accessToken = getCookie("accessToken");
  const body = {
    app_type: appType,
    country,
    group,
    language,
  };

  await axios
    .post(DISPLAY_TRANSLATION_URL, body, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      result = {
        result: true,
        translationVersion: res.data.translation_version,
        translations: res.data.translations,
      };
    })
    .catch(() => {
      result = {
        result: false,
        translationVersion: null,
        translations: null,
      };
    });
  return result;
};

export const getTranslationItem = async (dto) => {
  let result;

  const { country, appType, group, language, itemGroup, sequenceNumber } = dto;
  const accessToken = getCookie("accessToken");
  const body = {
    country,
    app_type: appType,
    group,
    language,
    item_group: itemGroup,
    sequence_number: sequenceNumber,
  };

  await axios
    .post(GET_TRANSLATION_ITEM_URL, body, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      result = {
        result: true,
        originalItem: res.data.original_item,
        translationItem: res.data.translation_item,
      };
    })
    .catch(() => {
      result = {
        result: false,
        originalItem: null,
        translationItem: null,
      };
    });
  return result;
};

export const editTranslation = async (dto) => {
  let result;
  const accessToken = getCookie("accessToken");

  await axios
    .patch(EDIT_TRANSLATION_ITEM_URL, dto, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      result = {
        result: true,
        translationVersion: res.data.translation_version,
        translations: res.data.translations,
      };
    })
    .catch(() => {
      result = {
        result: false,
        translationVersion: null,
        translations: null,
      };
    });
  return result;
};

export const getDealerData = async (id) => {
  let result;

  const accessToken = getCookie("accessToken");

  await axios
    .post(
      GET_DEALER_DATA_URL,
      {
        userId: id,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    .then((res) => {
      result = {
        res: true,
        data: res.data,
      };
    })
    .catch((e) => {
      result = {
        res: false,
        data: e.message,
      };
    });

  return result;
};

export const getAppIDList = async (nation) => {
  let result;
  const accessToken = getCookie("accessToken");

  await axios
    .post(
      GET_APPID_LIST_URL,
      {
        nation,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    .then((res) => {
      result = {
        result: true,
        data: res.data,
      };
    })
    .catch(() => {
      result = {
        result: false,
        data: null,
      };
    });

  return result;
};

export const getDashboardData = async (country) => {
  let result;
  const accessToken = getCookie("accessToken");

  await axios
    .post(
      GET_DASHBOARD_DATA_URL,
      {
        country,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    .then((res) => {
      result = {
        result: true,
        data: res.data,
      };
    })
    .catch(() => {
      result = {
        result: false,
        data: null,
      };
    });

  return result;
};

export const setSubscription = async (appId, message) => {
  let result;
  const accessToken = getCookie("accessToken");

  await axios
    .post(
      SET_SUBSCRIPTION_URL,
      {
        app_id: appId,
        log: message,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    .then((res) => {
      result = res.data;
    })
    .catch(() => {
      result = {
        result: false,
        acknowledge: false,
        data: null,
      };
    });

  return result;
};

export const deleteSubscription = async (dealerId, deleteTarget) => {
  let result;
  const accessToken = getCookie("accessToken");

  await axios
    .post(
      DELETE_SUBSCRIPTION_URL,
      {
        dealer_id: dealerId,
        delete_target: deleteTarget,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    .then((res) => {
      result = res.data;
    })
    .catch(() => {
      result = {
        // result: false,
        // acknowledge: false,
        // data: null,
      };
    });

  return result;
};

const getDate = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();

  let stringDate = year.toString();
  stringDate += month >= 10 ? month : `0${month}`;
  stringDate += day >= 10 ? day : `0${day}`;

  return stringDate;
};

export const getSubscribeLogs = async (nation) => {
  const accessDate = getDate();

  const body = {
    country: nation,
    access_date: accessDate,
  };

  let result;

  await axios
    .post(GET_SUBSCRIBE_LOG_URL, body)
    .then((res) => {
      result = {
        result: true,
        data: res.data,
      };
    })
    .catch(() => {
      result = {
        result: false,
        data: null,
      };
    });

  return result;
};

export const displayTemplate = async (nation, language, title, subtitle) => {
  let result;
  const accessToken = getCookie("accessToken");

  await axios
    .post(
      DISPLAY_TEMPLATE_URL,
      {
        nation,
        language,
        title,
        subtitle,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    .then((res) => {
      result = {
        result: true,
        pageMainTemplate: res.data.pageMainTemplate,
        translations: res.data.translations,
      };
    })
    .catch(() => {
      result = {
        result: false,
        translationVersion: null,
        translations: null,
      };
    });

  return result;
};

export const editTemplate = async (
  nation,
  language,
  title,
  subtitle,
  mainTemplate,
  templateData
) => {
  let result;
  const accessToken = getCookie("accessToken");

  const body = {
    nation,
    language,
    title,
    subtitle,
    mainTemplate,
    templateData,
  };

  await axios
    .patch(EDIT_TEMPLATE_URL, body, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((res) => {
      result = {
        result: true,
        translationVersion: res.data.translation_version,
        translations: res.data.translations,
      };
    })
    .catch(() => {
      result = {
        result: false,
        translationVersion: null,
        translations: null,
      };
    });

  return result;
};

export const getLatestRelease = async (target) => {
  let result;

  await axios
    .get(`${GET_LATEST_RELEASE_URL}/${target}`)
    .then((res) => {
      result = {
        result: true,
        version: res.data.version,
        url: res.data.url,
      };
    })
    .catch(() => {
      result = {
        result: false,
        version: "undefined",
        url: "undefined",
      };
    });

  return result;
};

export const getVersion = async (country) => {
  let result;

  await axios
    .post(GET_VERSION_URL, {
      country,
    })
    .then((res) => {
      result = {
        app: res.data.app,
        asset: res.data.asset,
        display: res.data.display,
        translations: res.data.translations,
      };
    })
    .catch(() => {
      result = {
        app: "undefined",
        asset: "undefined",
        display: "undefined",
        translations: "undefined",
      };
    });

  return result;
};

export const getAppIDByDealership = async (country) => {
  let result;
  const accessToken = getCookie("accessToken");

  await axios
    .post(
      GET_APP_ID_BY_DEALERSHIP_URL,
      {
        country,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((res) => {
      result = res.data;
    })
    .catch((e) => {
      result = null;
    });

  return result;
};

export const editSubscribe = async (body) => {
  let result;
  const accessToken = getCookie("accessToken");

  await axios
    .post(EDIT_SUBSCRIBE_URL, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => {
      result = res.data;
    })
    .catch((e) => {
      result = null;
    });

  return result;
};

export const getDealershipCountryList = async () => {
  let result;

  await axios
    .get(GET_DEALERSHIP_COUNTRY_LIST_URL)
    .then((res) => {
      result = res.data;
    })
    .catch(() => {
      result = [];
    });

  return result;
};

export const getHMEDashboardData = async () => {
  let result;
  const accessToken = getCookie("accessToken");

  await axios
    .get(GET_HME_DASHBOARD_DATA_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => {
      result = res.data;
    })
    .catch((e) => {
      result = null;
    });

  return result;
};
