import {
  AUSTRIA,
  BELGIUM,
  DENMARK,
  FINLAND,
  FRANCE,
  GERMANY,
  IRELAND,
  ICELAND,
  ITALY,
  MALTA,
  NETHERRANDS,
  NORWAY,
  PORTUGAL,
  SPAIN,
  SWEDEN,
  SWISS,
  UK,
  BULGARIA,
  CZECH,
  HUNGARY,
  POLAND,
  ROMANIA,
  SLOVENIA,
  CROATIA,
  SLOVAKIA,
  CYPRUS,
  GREECE,
  CANARY_ISLAND,
  REUNION,
  THAILAND,
  TURKEY,
} from "utils/constants";

export const getCountry = (country) => {
  if (country === AUSTRIA.code) return AUSTRIA;
  if (country === BELGIUM.code) return BELGIUM;
  if (country === DENMARK.code) return DENMARK;
  if (country === FINLAND.code) return FINLAND;
  if (country === FRANCE.code) return FRANCE;
  if (country === GERMANY.code) return GERMANY;
  if (country === IRELAND.code) return IRELAND;
  if (country === ICELAND.code) return ICELAND;
  if (country === ITALY.code) return ITALY;
  if (country === MALTA.code) return MALTA;
  if (country === NETHERRANDS.code) return NETHERRANDS;
  if (country === NORWAY.code) return NORWAY;
  if (country === PORTUGAL.code) return PORTUGAL;
  if (country === SPAIN.code) return SPAIN;
  if (country === SWEDEN.code) return SWEDEN;
  if (country === SWISS.code) return SWISS;
  if (country === UK.code) return UK;
  if (country === CZECH.code) return CZECH;
  if (country === BULGARIA.code) return BULGARIA;
  if (country === HUNGARY.code) return HUNGARY;
  if (country === POLAND.code) return POLAND;
  if (country === ROMANIA.code) return ROMANIA;
  if (country === SLOVENIA.code) return SLOVENIA;
  if (country === CROATIA.code) return CROATIA;
  if (country === SLOVAKIA.code) return SLOVAKIA;
  if (country === CYPRUS.code) return CYPRUS;
  if (country === GREECE.code) return GREECE;
  if (country === CANARY_ISLAND.code) return CANARY_ISLAND;
  if (country === REUNION.code) return REUNION;
  if (country === THAILAND.code) return THAILAND;
  if (country === TURKEY.code) return TURKEY;
  return null;
};

export const getCountryNameCode = (country) => {
  if (country === AUSTRIA.code) return `${AUSTRIA.name} - ${country}`;
  if (country === BELGIUM.code) return `${BELGIUM.name} - ${country}`;
  if (country === DENMARK.code) return `${DENMARK.name} - ${country}`;
  if (country === FINLAND.code) return `${FINLAND.name} - ${country}`;
  if (country === FRANCE.code) return `${FRANCE.name} - ${country}`;
  if (country === GERMANY.code) return `${GERMANY.name} - ${country}`;
  if (country === IRELAND.code) return `${IRELAND.name} - ${country}`;
  if (country === ICELAND.code) return `${ICELAND.name} - ${country}`;
  if (country === ITALY.code) return `${ITALY.name} - ${country}`;
  if (country === MALTA.code) return `${MALTA.name} - ${country}`;
  if (country === NETHERRANDS.code) return `${NETHERRANDS.name} - ${country}`;
  if (country === NORWAY.code) return `${NORWAY.name} - ${country}`;
  if (country === PORTUGAL.code) return `${PORTUGAL.name} - ${country}`;
  if (country === SPAIN.code) return `${SPAIN.name} - ${country}`;
  if (country === SWEDEN.code) return `${SWEDEN.name} - ${country}`;
  if (country === SWISS.code) return `${SWISS.name} - ${country}`;
  if (country === UK.code) return `${UK.name} - ${country}`;
  if (country === CZECH.code) return `${CZECH.name} - ${country}`;
  if (country === BULGARIA.code) return `${BULGARIA.name} - ${country}`;
  if (country === HUNGARY.code) return `${HUNGARY.name} - ${country}`;
  if (country === POLAND.code) return `${POLAND.name} - ${country}`;
  if (country === ROMANIA.code) return `${ROMANIA.name} - ${country}`;
  if (country === SLOVENIA.code) return `${SLOVENIA.name} - ${country}`;
  if (country === CROATIA.code) return `${CROATIA.name} - ${country}`;
  if (country === SLOVAKIA.code) return `${SLOVAKIA.name} - ${country}`;
  if (country === CYPRUS.code) return `${CYPRUS.name} - ${country}`;
  if (country === GREECE.code) return `${GREECE.name} - ${country}`;
  if (country === CANARY_ISLAND.code)
    return `${CANARY_ISLAND.name} - ${country}`;
  if (country === REUNION.code) return `${REUNION.name} - ${country}`;
  if (country === THAILAND.code) return `${THAILAND.name} - ${country}`;
  if (country === TURKEY.code) return `${TURKEY.name} - ${country}`;
  return null;
};

export const getCountryDefaultValue = (country) => {
  if (country === "AT")
    return { label: "Österreich - Austria - AT", name: "AT" };
  if (country === "BE") return { label: "Belgique - Belgium - BE", name: "BE" };
  if (country === "BG")
    return { label: "България - Bulgaria - BG", name: "BG" };
  if (country === "IC")
    return { label: "Islas Canarias - Canary Islands - IC", name: "IC" };
  if (country === "HR") return { label: "Hrvatska - Croatia - HR", name: "HR" };
  if (country === "CY") return { label: "Κυπριακή - Cyprus - CY", name: "CY" };
  if (country === "CZ") return { label: "Česko - Czechia - CZ", name: "CZ" };
  if (country === "DK") return { label: "Danmark - Denmark - DK", name: "DK" };
  if (country === "FI") return { label: "Suomi - Finland - FI", name: "FI" };
  if (country === "FR") return { label: "France - France - FR", name: "FR" };
  if (country === "DE")
    return { label: "Deutschland - Germany - DE", name: "DE" };
  if (country === "GR") return { label: "Ελλάδα - Greece - GR", name: "GR" };
  if (country === "HU")
    return { label: "Magyarország - Hungary - HU", name: "HU" };
  if (country === "IS") return { label: "Ísland - Iceland - IS", name: "IS" };
  if (country === "IE") return { label: "Éire - Ireland - IE", name: "IE" };
  if (country === "IT") return { label: "Italia - Italy - IT", name: "IT" };
  if (country === "MT") return { label: "Malta - Malta - MT", name: "MT" };
  if (country === "NL")
    return { label: "Nederland - Netherlands - NL", name: "NL" };
  if (country === "NO") return { label: "Norge - Norway - NO", name: "NO" };
  if (country === "PL") return { label: "Polska - Poland - PL", name: "PL" };
  if (country === "PT")
    return { label: "Portuguesa - Portugal - PT", name: "PT" };
  if (country === "RE")
    return { label: "La Réunion - Reunion - RE", name: "RE" };
  if (country === "RO") return { label: "România - Romania - RO", name: "RO" };
  if (country === "SK")
    return { label: "Slovensko - Slovakia - SK", name: "SK" };
  if (country === "SI")
    return { label: "Slovenija - Slovenia - SI", name: "SI" };
  if (country === "ES") return { label: "España - Spain - ES", name: "ES" };
  if (country === "SE") return { label: "Sverige - Sweden - SE", name: "SE" };
  if (country === "CH")
    return { label: "Swiss - Switzerland - CH", name: "CH" };
  if (country === "UK")
    return { label: "United Kingdom - United Kingdom - UK", name: "UK" };
  if (country === "HME") return { label: "ALL", name: "HME" };
  if (country === "TR") return { label: "Türkiye - Turkey - TR", name: "TR" };
  if (country === "TH")
    return { label: "ประเทศไทย - Thailand - TH", name: "TH" };
  // if (country === "BA") return { label: "Bosna i Hercegovina - BA", name: "BA" };
  // if (country === "XC") return { label: "Ceuta - XC", name: "XC" };
  // if (country === "EE") return { label: "Eesti - EE", name: "EE" };
  // if (country === "LV") return { label: "Latvija - LV", name: "LV" };
  // if (country === "LT") return { label: "Lietuva - LT", name: "LT" };
  // if (country === "RS") return { label: "Србија - RS", name: "RS" };
  return null;
};

export const getCountryCodeByCompanyCode = (companyCode) => {
  if (companyCode === "HA02") return "CZ";
  if (companyCode === "HA03") return "SK";
  if (companyCode === "HA04") return "NO";
  if (companyCode === "HA05") return "PL";
  if (companyCode === "HB02") return "UK";
  if (companyCode === "HB04") return "IT";
  if (companyCode === "HB05") return "ES";
  if (companyCode === "HB06") return "FR";
  if (companyCode === "HB07") return "DE";
  if (companyCode === "HB08") return "NL";
  if (companyCode === "C01") return "AT";
  if (companyCode === "C02") return "BE";
  if (companyCode === "C04") return "DK";
  if (companyCode === "C05") return "FI";
  if (companyCode === "C09") return "IS";
  if (companyCode === "C10") return "IE";
  if (companyCode === "C13") return "MT";
  if (companyCode === "C16") return "PT";
  if (companyCode === "C18") return "SE";
  if (companyCode === "C19") return "CH";
  if (companyCode === "C23") return "BG";
  if (companyCode === "C26") return "HU";
  if (companyCode === "C28") return "RO";
  if (companyCode === "C32") return "SI";
  if (companyCode === "C33") return "HR";
  if (companyCode === "D22") return "CY";
  if (companyCode === "D26") return "TR";
  if (companyCode === "D27") return "GR";
  if (companyCode === "E45") return "IC";
  if (companyCode === "E49") return "RE";
  return null;
};
