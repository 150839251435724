// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Images
import bgImage from "assets/images/bg-sign-in-basic.png";
import slide1 from "assets/images/login-slide/slide1.png";
import slide2 from "assets/images/login-slide/slide2.png";
// import slide3 from "assets/images/login-slide/slide3.png";
import slide3 from "assets/images/login-slide/slide3.svg";
import { useState, useLayoutEffect } from "react";

// css
import "./index.css";
// util
// import { isValidPassword } from "utils/functions/validation";
import {
  accountValidate,
  autosignin,
  loginThailand,
} from "utils/functions/axios";
import { useNavigate } from "react-router-dom";
// store
import useDealerStore from "utils/stores/dealer.store";
import useLanguageStore from "utils/stores/language.store";
// import { getJWTCookie } from "utils/functions/cookie";

// import { Cookies } from "react-cookie";
import { removeAllCookie } from "utils/functions/cookie";

// slide
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// loading indicator
import CircularProgress from "@mui/material/CircularProgress";
// import { getCountryDefaultValue } from "utils/functions/country";

// const cookies = new Cookies();

function Basic() {
  //               variable               //
  const navigate = useNavigate();
  const [init, setInit] = useState(false);
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [alertFlag, setAlertFlag] = useState(false);

  const { setDealer, setUserType, resetDealer } = useDealerStore();
  const { resetLanguage, temporaryModal, setTemporaryNotice } =
    useLanguageStore();
  const [loading, setLoading] = useState(false);
  //               variable               //

  //               function               //
  const onIdHandelr = (e) => {
    setAlertFlag(false);
    setId(e.target.value);
  };
  const onPwHandelr = (e) => {
    setAlertFlag(false);
    setPassword(e.target.value);
  };

  const autoLogin = async () => {
    const tokenValue = window.location.search.substring(7);

    const { result, country, name, userId, userType } = await autosignin(
      tokenValue
    );

    setUserType(userType);

    if (result) {
      setDealer({
        id: userId,
        dealerName: name,
        name,
        country,
        userType,
      });

      // localStorage.setItem("country", country);
      const route =
        userType === "D" ? "../admin/download" : "../admin/dashboard";
      navigate(route);
    } else {
      removeAllCookie();
    }
  };

  function sendEmail() {
    // Replace the following email address with the desired recipient
    const recipientEmail = "hawkeye@wondermove.net";

    // Replace the subject and body with the desired email content
    const emailSubject = "";
    const emailBody = "";

    // Construct the mailto URI with recipient, subject, and body
    const mailtoURI = `mailto:${recipientEmail}?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;

    // Open the user's default email client
    window.location.href = mailtoURI;
  }

  const onSignInHandler = async () => {
    setLoading(true);
    let res;

    if (process.env.REACT_APP_ENV === "dev") {
      const thailandAccountList = ["hmtadm01", "hmtdealer01", "hmtdealer02"];
      if (thailandAccountList.includes(id))
        res = await loginThailand(id, password);
      else res = await accountValidate(id, password);
    } else res = await accountValidate(id, password);
    const { result, dealerName, name, country, userType } = res;
    //   await accountValidate(id, password);
    if (!result) {
      setAlertFlag(true);
    } else {
      setDealer({ id, dealerName, name, country, userType });
      setUserType(userType);
      // localStorage.setItem("country", country);
      const route =
        userType === "D" ? "../admin/download" : "../admin/dashboard";
      navigate(route);
    }
    setLoading(false);
  };
  //               function               //

  //               hook               //
  useLayoutEffect(() => {
    async function loginByToken() {
      await autoLogin();
      setInit(true);
      if (process.env.REACT_APP_ENV === "dev") {
        setTemporaryNotice(true);
      }
    }
    if (!init) {
      // const countryCookie = getCountryCookie;
      // if (countryCookie !== "" || countryCookie !== undefined) {
      //   removeAllCookie();
      // }
      removeAllCookie();
      resetDealer();
      resetLanguage();

      loginByToken();
    }

    // const accessToken = cookies.get("access_token");
    // console.log(accessToken);
    // if (accessToken) navigate("../admin/dashboards");
  }, []);
  //               hook               //

  //               component               //
  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      You entered the wrong{" "}
      <MDTypography
        component="a"
        href="#"
        variant="body2"
        fontWeight="medium"
        color="white"
      >
        ID
      </MDTypography>{" "}
      or{" "}
      <MDTypography
        component="a"
        href="#"
        variant="body2"
        fontWeight="medium"
        color="white"
      >
        password
      </MDTypography>
      . Please check your input again.
    </MDTypography>
  );

  const settings = {
    dots: true,
    infinite: true,
    appendDots: (dots) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0px 20px",
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    dotsClass: "dots_custom",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  //               component               //

  return (
    <div className="loginContainer">
      <MDBox
        width="50%"
        display="flex"
        alignItems="center" // vertical center
        justifyContent="center" // horizontal center
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Card style={{ opacity: 1 }}>
          <MDBox
            width="550px"
            height="596px"
            borderRadius="12px"
            bgcolor="#FFFFFF"
            padding="60px 25px 46px 25px"
          >
            <Slider {...settings}>
              <MDBox paddingLeft="30px" paddingRight="30px">
                <MDBox
                  height="101px"
                  marginBottom="60px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  textAlign="center"
                >
                  <MDBox>
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      fontSize="24px"
                      sx={{ color: "#1C1B1B" }}
                    >
                      Easy installation
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      fontSize="14px"
                      lineHeight="17.3px"
                      sx={{ color: "#1C1B1B" }}
                    >
                      As soon as you download and install the file from the
                      <br />
                      Downloads menu, all three apps are ready to go
                      <br /> in one neat package.
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  width="450px"
                  height="250px"
                  marginBottom="27.5px"
                  sx={{
                    backgroundImage: `url(${slide1})`,
                    backgroundSize: "cover", // 이미지가 박스를 꽉 채우도록 설정
                    backgroundPosition: "center", // 이미지가 박스 중앙에 위치하도록 설정
                    backgroundRepeat: "no-repeat", // 이미지가 반복되지 않도록 설정
                  }}
                />
                <MDBox height="44px" marginBottom="27.5px">
                  <MDBox>
                    <MDTypography
                      fontWeight="medium"
                      fontSize="10px"
                      lineHeight="14px"
                      sx={{ color: "#7F7F7E" }}
                    >
                      Device requirements
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDTypography
                      fontWeight="medium"
                      fontSize="10px"
                      lineHeight="14px"
                      paddingLeft="5px"
                      sx={{ color: "#7F7F7E" }}
                    >
                      • &nbsp;Touchscreen Monitor: Full HD (1920*1080)
                      <br />• &nbsp;PC: Windows OS / CPU i7 / 16GB RAM / 256GB
                      SSD / NVIDIA P1000 or higher
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox paddingLeft="30px" paddingRight="30px">
                <MDBox
                  height="101px"
                  marginBottom="60px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  textAlign="center"
                >
                  <MDBox>
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      fontSize="24px"
                      sx={{ color: "#1C1B1B" }}
                    >
                      Automatic updates
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      fontSize="14px"
                      lineHeight="17.3px"
                      sx={{ color: "#1C1B1B" }}
                    >
                      Get automatic updates for the latest version hassel-free
                      <br />
                      everytime the app launches.
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  width="450px"
                  height="250px"
                  marginBottom="27.5px"
                  sx={{
                    backgroundImage: `url(${slide2})`,
                    backgroundSize: "cover", // 이미지가 박스를 꽉 채우도록 설정
                    backgroundPosition: "center", // 이미지가 박스 중앙에 위치하도록 설정
                    backgroundRepeat: "no-repeat", // 이미지가 반복되지 않도록 설정
                  }}
                />
              </MDBox>
              <MDBox paddingLeft="30px" paddingRight="30px">
                <MDBox
                  height="101px"
                  marginBottom="60px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  textAlign="center"
                >
                  <MDBox>
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      fontSize="24px"
                      sx={{ color: "#1C1B1B" }}
                    >
                      Multi-screen support
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      fontSize="14px"
                      lineHeight="17.3px"
                      sx={{ color: "#1C1B1B" }}
                    >
                      Supports running in a One PC +<br />
                      multi-screen environment.
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  width="450px"
                  height="250px"
                  marginBottom="27.5px"
                  sx={{
                    backgroundImage: `url(${slide3})`,
                    backgroundSize: "cover", // 이미지가 박스를 꽉 채우도록 설정
                    backgroundPosition: "center", // 이미지가 박스 중앙에 위치하도록 설정
                    backgroundRepeat: "no-repeat", // 이미지가 반복되지 않도록 설정
                  }}
                />
              </MDBox>
            </Slider>
          </MDBox>
        </Card>
      </MDBox>
      <MDBox width="50%" position="relative">
        <MDBox marginTop="254px" display="flex" justifyContent="center">
          <MDBox margin="10px" width="" minWidth="380px" height="314px">
            <MDTypography
              variant="h6"
              fontWeight="light"
              color="#002C5F"
              fontSize="24px"
            >
              Admin Portal for
            </MDTypography>
            <MDTypography
              variant="h6"
              fontWeight="bold"
              color="002C5F"
              fontSize="30px"
              mt={1}
            >
              Hyundai EV Showroom
            </MDTypography>
            <MDBox mt="60px">
              <MDInput
                type="text"
                label="ID"
                onChange={onIdHandelr}
                fullWidth
              />
            </MDBox>
            <MDBox mt="24px">
              <MDInput
                type="password"
                label="Password"
                onChange={onPwHandelr}
                fullWidth
              />
            </MDBox>
            <MDButton
              style={{
                float: "right",
                backgroundColor: "#002C5f",
                color: "white",
                marginTop: "40px",
                marginBottom: "20px",
              }}
              disabled={loading || !id || !password}
              fullWidth
              onClick={onSignInHandler}
            >
              <MDTypography fontWeight="medium" color="#002C5F" fontSize="12px">
                {loading ? (
                  <CircularProgress
                    size={14}
                    sx={{
                      color: "#FFFFFF",
                      height: "10px",
                    }}
                  />
                ) : (
                  "Login with Hyundai DP Account"
                )}
              </MDTypography>
            </MDButton>
            {alertFlag && (
              <MDAlert color="error">{alertContent("error")}</MDAlert>
            )}
          </MDBox>
        </MDBox>

        <MDBox
          display="flex"
          justifyContent="space-between"
          padding="0 40px"
          sx={{
            position: "absolute",
            bottom: "30px",
            left: 0,
            right: 0,
          }}
        >
          <MDBox
            onClick={() => {
              sendEmail();
            }}
            sx={{ cursor: "pointer" }}
          >
            <MDTypography
              variant="B7"
              fontWeight="light"
              color="#002C5F"
              fontSize="14px"
            >
              Need Help?
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography
              variant="B7"
              fontWeight="light"
              color="#002C5F"
              fontSize="14px"
            >
              © 2023 wondermove
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </div>
  );
}

export default Basic;
