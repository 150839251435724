// const API_URL = "http://localhost:4000/";
// const API_URL = "https://evshowroomdev.germanywestcentral.cloudapp.azure.com/";
// const API_URL =
//   "https://backend-evshowroom.germanywestcentral.cloudapp.azure.com/";
const API_URL = process.env.REACT_APP_APIURL;

// export const SIGNIN_URL = "http://localhost:4000/apis/auth/loginAdmin";
// export const AUTO_LOGIN_URL = "http://localhost:4000/apis/auth/autoLogin";
// export const ADMIN_DISPLAY_SETUP_URL =
//   "http://localhost:4000/apis/admin/displaySetup";
// export const GET_IMAGE_URL = "http://localhost:4000/apis/file/getImage/";
// export const SAVE_ELECTRIFIED_SETTING_URL =
//   "http://localhost:4000/apis/admin/settingSetup";
// export const DISPLAY_TRANSLATION_URL =
//   "http://localhost:4000/apis/admin/displayTranslation";
// export const GET_TRANSLATION_ITEM_URL =
//   "http://localhost:4000/apis/admin/displayTranslationItem";
// export const EDIT_TRANSLATION_ITEM_URL =
//   "http://localhost:4000/apis/admin/editTranslation";

// export const GET_DISPLAY_VEHICLE_URL =
//   "http://localhost:4000/apis/admin/getDisplayVehicle";
// export const EDIT_DISPLAY_VEHICLE_URL =
//   "http://localhost:4000/apis/admin/editDisplayVehicle";
// export const GET_DEALER_DATA_URL =
//   "http://localhost:4000/apis/admin/getDealerData";
// export const GET_APPID_LIST_URL =
//   "http://localhost:4000/apis/admin/getAppIDList";
// export const SET_SUBSCRIPTION_URL =
//   "http://localhost:4000/apis/admin/setSubscription";
// export const GET_SUBSCRIBE_LOG_URL =
//   "http://localhost:4000/apis/log/getSubscribeLog";
// export const DISPLAY_TEMPLATE_URL =
//   "http://localhost:4000/apis/admin/displayTemplate";
// export const EDIT_TEMPLATE_URL =
//   "http://localhost:4000/apis/admin/editTemplate";
// export const GET_LATEST_RELEASE_URL = "http://localhost:4000/apis/release";
// export const GET_VERSION_URL = "http://localhost:4000/apis/version/getVersion";

export const LOGIN_THAILAND_URL = `${API_URL}apis/auth/loginThailand`;
export const SIGNIN_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/auth/loginAdmin";
  `${API_URL}apis/auth/loginAdmin`;
export const AUTO_LOGIN_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/auth/autoLogin";
  `${API_URL}apis/auth/autoLogin`;
export const ADMIN_DISPLAY_SETUP_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/displaySetup";
  `${API_URL}apis/admin/displaySetup`;
export const GET_IMAGE_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/file/getImage/";
  `${API_URL}apis/file/getImage/`;
export const SAVE_ELECTRIFIED_SETTING_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/settingSetup";
  `${API_URL}apis/admin/settingSetup`;
export const DISPLAY_TRANSLATION_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/displayTranslation";
  `${API_URL}apis/admin/displayTranslation`;
export const GET_TRANSLATION_ITEM_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/displayTranslationItem";
  `${API_URL}apis/admin/displayTranslationItem`;
export const EDIT_TRANSLATION_ITEM_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/displayTranslationItem";
  `${API_URL}apis/admin/displayTranslationItem`;

export const GET_DISPLAY_VEHICLE_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/getDisplayVehicle";
  `${API_URL}apis/admin/getDisplayVehicle`;
export const EDIT_DISPLAY_VEHICLE_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/editDisplayVehicle";
  `${API_URL}apis/admin/editDisplayVehicle`;
export const GET_DEALER_DATA_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/getDealerData";
  `${API_URL}apis/admin/getDealerData`;
export const GET_APPID_LIST_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/getAppIDList";
  `${API_URL}apis/admin/getAppIDList`;
export const SET_SUBSCRIPTION_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/setSubscription";
  `${API_URL}apis/admin/setSubscription`;
export const DELETE_SUBSCRIPTION_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/deleteAppSubscribe";
  `${API_URL}apis/admin/deleteAppSubscribe`;
export const GET_SUBSCRIBE_LOG_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/log/getSubscribeLog";
  `${API_URL}apis/log/getSubscribeLog`;
export const DISPLAY_TEMPLATE_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/displayTemplate";
  `${API_URL}apis/admin/displayTemplate`;
export const EDIT_TEMPLATE_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/admin/editTemplate";
  `${API_URL}apis/admin/editTemplate`;
export const GET_LATEST_RELEASE_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/release";
  `${API_URL}apis/release`;
export const GET_VERSION_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/version/getVersion";
  `${API_URL}apis/version/getVersion`;
export const GET_DASHBOARD_DATA_URL =
  // "https://evshowroom.germanywestcentral.cloudapp.azure.com/apis/version/getVersion";
  `${API_URL}apis/admin/getDashboardData`;
export const GET_APP_ID_BY_DEALERSHIP_URL = `${API_URL}apis/admin/getAppIDByDealership`;
export const EDIT_SUBSCRIBE_URL = `${API_URL}apis/admin/editSubscribe`;
export const GET_DEALERSHIP_COUNTRY_LIST_URL = `${API_URL}apis/integration/getDealershipCountryList`;
export const GET_RELEASE_URL = `${API_URL}apis/release/windows/getRelease`;
export const GET_HME_DASHBOARD_DATA_URL = `${API_URL}apis/admin/getHMEDashboardData`;

export const AUSTRIA = { code: "AT", name: "Österreich", enName: "Austria" };
export const BELGIUM = {
  code: "BE",
  name: "België",
  enName: "Belgium",
};
export const DENMARK = {
  code: "DK",
  name: "Danmark",
  enName: "Denmark",
};
export const FINLAND = {
  code: "FI",
  name: "Suomi",
  enName: "Finland",
};
export const FRANCE = {
  code: "FR",
  name: "France",
  enName: "France",
};
export const GERMANY = {
  code: "DE",
  name: "Deutschland",
  enName: "Germany",
};
export const IRELAND = { code: "IE", name: "Éire", enName: "Ireland" };
export const ICELAND = { code: "IS", name: "Ísland", enName: "Iceland" };
export const ITALY = { code: "IT", name: "Italia", enName: "Italy" };
export const MALTA = { code: "MT", name: "Malta", enName: "Malta" };
export const NETHERRANDS = {
  code: "NL",
  name: "Nederland",
  enName: "Netherlands",
};
export const NORWAY = { code: "NO", name: "Norge", enName: "Norway" };
export const PORTUGAL = { code: "PT", name: "Portugal", enName: "Portugal" };
export const SPAIN = { code: "ES", name: "España", enName: "Spain" };
export const SWEDEN = { code: "SE", name: "Sverige", enName: "Sweden" };
export const SWISS = { code: "CH", name: "Schweiz", enName: "Switzerland" };
export const UK = {
  code: "UK",
  name: "the United Kingdom",
  enName: "the United Kingdom",
};
export const BULGARIA = { code: "BG", name: "България", enName: "Bulgaria" };
export const CZECH = { code: "CZ", name: "Česko", enName: "Czech Republic" };
export const HUNGARY = { code: "HU", name: "Magyarország", enName: "Hungary" };
export const POLAND = { code: "PL", name: "Polska", enName: "Poland" };
export const ROMANIA = { code: "RO", name: "România", enName: "Romania" };
export const SLOVENIA = {
  code: "SI",
  name: "Republika Slovenija",
  enName: "Slovenia",
};
export const CROATIA = { code: "HR", name: "Hrvatska", enName: "Croatia" };
export const SLOVAKIA = { code: "SK", name: "Slovensko", enName: "Slovakia" };
export const CYPRUS = { code: "CY", name: "Κύπρος", enName: "Cyprus" };
export const GREECE = { code: "GR", name: "Ελλάδα", enName: "Greece" };
export const CANARY_ISLAND = {
  code: "IC",
  name: "Las Islas Canarias",
  enName: "Canary Islands",
};
export const REUNION = { code: "RE", name: "Reunion", enName: "Reunion" };
export const THAILAND = { code: "TH", name: "ภาษาไทย", enName: "Thailand" };
export const TURKEY = { code: "TR", name: "Türkiye", enName: "Turkey" };

export const SHOWROOMS = [
  { label: "Electrified", name: "Electrified" },
  { label: "Powertrain", name: "Powertrain" },
  { label: "Connectivity", name: "Connectivity" },
];

export const ELECTRIFIED_CLASS = [
  { label: "HIGHLIGHTS", name: "HIGHLIGHTS" },
  { label: "CHARGING", name: "CHARGING" },
  { label: "BENEFITS", name: "BENEFITS" },
];

// export const LANGUAGE = [
//   { label: "English - English - EN", name: "en" },

//   { label: "Bulgarian - Bulgarian - BG", name: "bg" },
//   { label: "Croatian - Croatian - HR", name: "hr" },
//   { label: "Greek - Greek - GR", name: "gr" },
//   { label: "Spanish - Spanish - ES", name: "es" },
//   { label: "Finnish - Finnish - FI", name: "fi" },
//   { label: "Hungarian - Hungarian - HU", name: "hu" },
//   { label: "Portuguese - Portuguese - PT", name: "pt" },
//   { label: "Romanian - RO", name: "ro" },
//   { label: "česky - Czech - CS", name: "cs" },
//   { label: "Deutsch - German - DE", name: "de" },
//   { label: "español - Spanish - ES", name: "es" },
//   { label: "français - French - FR", name: "fr" },
//   { label: "italiano - Italian - IT", name: "it" },
//   { label: "Nederlands - Dutch - NL", name: "nl" },
//   { label: "norsk - Norwegian - NO", name: "no" },
//   { label: "polski - Polish - PL", name: "pl" },
//   { label: "slovenčina - Slovak - SK", name: "sk" },

//   { label: "Austria - Österreich - AT", name: "at" },
//   { label: "Belgium - Belgique - BE", name: "be" },
//   { label: "Danmark - Danmark - DK", name: "dk" },

//   { label: "Island - Ísland - IS", name: "is" },
//   { label: "Ireland - Éire - IE", name: "ie" },
//   { label: "Malta - Malta - MT", name: "mt" },
//   { label: "Sverige - Sverige - SE", name: "se" },
//   { label: "Swiss - Swiss - CH", name: "ch" },
//   { label: "Slovenia - Slovenija - SI", name: "si" },
//   { label: "Cyprus - Κυπριακή - CY", name: "cy" },
//   { label: "Islas Canarias - Islas Canarias - IC", name: "ic" },
//   { label: "Reunion - La Réunion - RE", name: "re" },
//   { label: "Estonian - ET", name: "et" },
//   { label: "Hebrew - HE", name: "he" },
//   { label: "Italian - IT", name: "it" },
//   { label: "Latvian - LV", name: "lv" },
//   { label: "Lithuanian - LT", name: "lt" },
//   { label: "Swedush - SV", name: "sv" },
//   { label: "Russian - RU", name: "ru" },
//   { label: "Serbian - SR-CS", name: "sr-CS" },
//   { label: "Slovenian - SL", name: "sl" },
//   { label: "Thai - TH", name: "th" },
//   { label: "Turkish - TR", name: "tr" },
//   { label: "Arabic - AR-XA", name: "ar-XA" },
//   { label: "Chech - CS", name: "cs" },
//   { label: "Danish - DA", name: "da" },
//   { label: "German - DE", name: "de" },
//   { label: "French - FR", name: "fr" },
//   { label: "Irish - GA", name: "ga" },
//   { label: "Hindi - HI", name: "hi" },
//   { label: "Japanese - JA", name: "ja" },
//   { label: "Korean - KO", name: "ko" },
//   { label: "Dutch - NL", name: "nl" },
//   { label: "Polish - PL", name: "pl" },
//   { label: "Slobak - SK", name: "sk" },
//   { label: "Ukrainian - UK", name: "uk" },
//   { label: "Chinese_s - ZH-CHS", name: "zh-CHS" },
//   { label: "Chinese_t - ZH-CHT", name: "zh-CHT" },
// ];

export const LANGUAGE = [
  { label: "Deutsch - German - DE", name: "de" },
  { label: "English - English - EN", name: "en" },
  { label: "Gaeilge - Irish - GA", name: "ga" },
  { label: "Malti - Maltese - MT", name: "mt" },
  { label: "Nederlands - Dutch - NL", name: "nl" },
  { label: "Svenska - Swedish - SV", name: "sv" },
  { label: "Türkçe - Turkish - TR", name: "tr" },
  { label: "bosanski - Bosnian - BS", name: "bs" },
  { label: "dansk - Danish - DA", name: "da" },
  { label: "español - Spanish - ES", name: "es" },
  { label: "français - French - FR", name: "fr" },
  { label: "hrvatski - Croatian - HR", name: "hr" },
  { label: "italiano - Italian - IT", name: "it" },
  { label: "limba română - Romanian - RO", name: "ro" },
  { label: "magyar nyelv - Hungarian - HU", name: "hu" },
  { label: "norsk - Norwegian - NO", name: "no" },
  { label: "polski - Polish - PL", name: "pl" },
  { label: "português - Portuguese - PT", name: "pt" },
  { label: "romontsch - Romansh - RM", name: "rm" },
  { label: "slovenčina - Slovak - SK", name: "sk" },
  { label: "slovenščina - Slovene - SL", name: "sl" },
  { label: "suomi - Finnish - FI", name: "fi" },
  { label: "ελληνικά - Greek - EL", name: "el" },
  { label: "čeština - Czech - CS", name: "cs" },
  { label: "български - Bulgarian - BG", name: "bg" },
  { label: "српски - Serbian - SR", name: "sr" },
  { label: "íslenska - Icelandic - IS", name: "is" },
  { label: "eesti keel - Estonian - ET", name: "et" },
  { label: "latviski - Latvian - LV", name: "lv" },
  { label: "lietuvių - Lithuanian - LT", name: "lt" },
  { label: "ภาษาไทย - Thai - TH", name: "th" },
]
  .filter((item) => process.env.REACT_APP_ENV !== "prd" || item.name !== "th")
  .sort((a, b) => a.name.localeCompare(b.name));

export const TMP_ELECTRIFIED = [
  { label: "All About EV", name: "All About EV" },
  { label: "New KONA Electric", name: "New KONA Electric" },
  { label: "KONA Electric", name: "KONA Electric" },
  { label: "IONIQ 5", name: "IONIQ 5" },
  { label: "IONIQ 5 N", name: "IONIQ 5 N" },
  { label: "IONIQ 6", name: "IONIQ 6" },
];

export const TMP_POWERTRAIN = [
  { label: "Full Electric", name: "Full Electric" },
  { label: "Mild Hybrid", name: "Mild Hybrid" },
  { label: "Hydrogen", name: "Hydrogen" },
  { label: "Plug-in Hybrid", name: "Plug-in Hybrid" },
  { label: "Hybrid", name: "Hybrid" },
];

export const TMP_CONNECTIVITY = [
  { label: "Bluelink", name: "Bluelink" },
  { label: "Charge myHyundai", name: "Charge myHyundai" },
];

export const TMP_COUNTRY = [
  // { label: "Bosna i Hercegovina - BA", name: "BA" },
  // { label: "Česko - CZ", name: "CZ" },
  // { label: "Ceuta - XC", name: "XC" },
  // { label: "Nederland - NL", name: "NL" },
  // { label: "Norge - NO", name: "NO" },
  // { label: "Slovensko - SK", name: "SK" },
  // { label: "Türkiye - TR", name: "TR" },
  // { label: "United Kingdom - GB", name: "GB" },
  // { label: "Србија - RS", name: "RS" },
  // { label: "Polska - PL", name: "PL" },
  // { label: "Italia - IT", name: "IT" },
  // { label: "Latvija - LV", name: "LV" },
  // { label: "Lietuva - LT", name: "LT" },
  // { label: "Deutschland - DE", name: "DE" },
  // { label: "Eesti - EE", name: "EE" },
  // { label: "España - ES", name: "ES" },
  // { label: "France - FR", name: "FR" },
  { label: "ALL", name: "HME" },
  ...[
    { label: "Österreich - Austria - AT", name: "AT" },
    { label: "Belgique - Belgium - BE", name: "BE" },
    { label: "България - Bulgaria - BG", name: "BG" },
    { label: "Islas Canarias - Canary Islands - IC", name: "IC" },
    { label: "Hrvatska - Croatia - HR", name: "HR" },
    { label: "Κυπριακή - Cyprus - CY", name: "CY" },
    { label: "Česko - Czechia - CZ", name: "CZ" },
    { label: "Danmark - Denmark - DK", name: "DK" },
    { label: "Suomi - Finland - FI", name: "FI" },
    { label: "France - France - FR", name: "FR" },
    { label: "Deutschland - Germany - DE", name: "DE" },
    { label: "Ελλάδα - Greece - GR", name: "GR" },
    { label: "Magyarország - Hungary - HU", name: "HU" },
    { label: "Ísland - Iceland - IS", name: "IS" },
    { label: "Éire - Ireland - IE", name: "IE" },
    { label: "Italia - Italy - IT", name: "IT" },
    { label: "Malta - Malta - MT", name: "MT" },
    { label: "Nederland - Netherlands - NL", name: "NL" },
    { label: "Norge - Norway - NO", name: "NO" },
    { label: "Polska - Poland - PL", name: "PL" },
    { label: "Portuguesa - Portugal - PT", name: "PT" },
    { label: "România - Romania - RO", name: "RO" },
    { label: "Slovensko - Slovakia - SK", name: "SK" },
    { label: "Slovenija - Slovenia - SI", name: "SI" },
    { label: "España - Spain - ES", name: "ES" },
    { label: "Sverige - Sweden - SE", name: "SE" },
    { label: "Swiss - Switzerland - CH", name: "CH" },
    { label: "United Kingdom - United Kingdom - UK", name: "UK" },
    { label: "ประเทศไทย - Thailand - TH", name: "TH" },
    { label: "Türkiye - Turkey - TR", name: "TR" },
  ].sort((a, b) => a.name.localeCompare(b.name)),
].filter((item) => process.env.REACT_APP_ENV !== "prd" || item.name !== "TH");

export const BRAND = "EV SHOWROOM Admin";

export const ELECTRIFIED = "Electrified";
export const POWERTRAIN = "Powertrain";
export const CONNECTIVTY = "Connectivity";
export const EVSHOWROOM = "EV Hyundai";

export const TEMPLATE1 = "Template 1";
export const TEMPLATE2 = "Template 2";
export const TEMPLATE3 = "Template 3";

export const DOWNLOAD_WINDOWS_TEXT = "Desktop app for Windows";
export const DOWNLOAD_MAC_TEXT = "Desktop app for Mac";

export const UNDEFINED = "UNDEFINED";
