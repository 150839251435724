import create from "zustand";
import { getCookie } from "utils/functions/cookie";

const initDealer = {
  id: getCookie("userId"),
  dealerName: getCookie("dealerName"),
  name: getCookie("name"),
  country: getCookie("country"),
  userType: getCookie("userType"),
};

const useStore = create((set) => ({
  dealer: initDealer,
  userType: "",
  setDealer: (dealer) => set(() => ({ dealer })),
  removeDealer: () => set(() => ({ dealer: null })),
  setUserType: (userType) => set(() => ({ userType })),
  resetDealer: () => set(() => ({ dealer: initDealer, userType: "" })),
}));

export default useStore;
