import { Autocomplete, Card, Grid } from "@mui/material";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import FormField from "layouts/pages/account/components/FormField";
import { TMP_COUNTRY } from "utils/constants";

// import PropTypes from "prop-types";
import { useLayoutEffect, useState } from "react";
import { getCountryDefaultValue } from "utils/functions/country";
import useLanguageStore from "utils/stores/language.store";
import useDealerStore from "utils/stores/dealer.store";
import { getCookie } from "utils/functions/cookie";

function CountrySelectorCard() {
  const [defaultCountry, setDefaultCountry] = useState({});
  const [country, setSelectedCountry] = useState({});
  const { language, setLanguage, setChange } = useLanguageStore();
  const { dealer, userType } = useDealerStore();
  const [readonly, setReadonly] = useState(false);

  const handleChangeCountry = (e, v) => {
    if (!v) {
      setLanguage("");
    } else {
      setSelectedCountry(v);
      setLanguage(v.name);
    }

    setChange(true);
  };

  useLayoutEffect(() => {
    const countryCookie = getCookie("country");
    const { country: dealerCountry } = dealer;
    const nation = countryCookie !== undefined ? countryCookie : dealerCountry;

    const isHME = getCookie("userType") === "E" || userType === "E";
    // const countryCode = language === "" ? nation : language;
    let countryCode = "";
    if (language === "") {
      if (isHME) countryCode = "HME";
      else countryCode = nation;
    } else countryCode = language;

    const countryData = getCountryDefaultValue(countryCode);

    if (countryData) {
      setLanguage(countryData.name);
    }
    setDefaultCountry(countryData);
    setSelectedCountry(countryData);

    setReadonly(!isHME);
  }, []);

  return (
    <Grid item sm={12} lg={3}>
      <Card>
        <MDBox p="16px">
          <MDTypography
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
            color="deepGray"
          >
            Region
          </MDTypography>
          <Autocomplete
            mx={1}
            readOnly={readonly}
            options={TMP_COUNTRY}
            defaultValue={defaultCountry}
            value={country}
            onChange={(event, value) => handleChangeCountry(event, value)}
            renderInput={(params) => (
              <FormField
                {...params}
                label="Region"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </MDBox>
      </Card>
    </Grid>
  );
}

// CountrySelectorCard.defaultProps = {
//   setter: () => {},
// };

// CountrySelectorCard.propTypes = {
//   setter: PropTypes.func,
// };

export default CountrySelectorCard;
