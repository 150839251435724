// import { Cookies } from "react-cookie";
import Cookies from "universal-cookie";

export const cookies = new Cookies();

export const getCookie = (key) => {
  const cookie = document.cookie.split(";").map((item) => item.trim());

  for (let i = 0; i < cookie.length; i += 1) {
    if (cookie[i].startsWith(`${key}`)) {
      const value = cookie[i].substring(key.length + 1);

      return value;
    }
  }

  return undefined;
};

export const removeAllCookie = () => {
  // cookies.remove("accessToken", { path: "/" });
  // cookies.remove("userId", { path: "/" });
  // cookies.remove("name", { path: "/" });
  // cookies.remove("dealerName", { path: "/" });
  // cookies.remove("country", { path: "/" });
  // cookies.remove("userType", { path: "/" });

  cookies.set("accessToken", undefined, {
    maxAge: 0,
    path: "/",
  });
  cookies.set("userId", undefined, {
    maxAge: 0,
    path: "/",
  });
  cookies.set("name", undefined, {
    maxAge: 0,
    path: "/",
  });
  cookies.set("dealerName", undefined, {
    maxAge: 0,
    path: "/",
  });
  cookies.set("country", undefined, {
    maxAge: 0,
    path: "/",
  });
  cookies.set("userType", undefined, {
    maxAge: 0,
    path: "/",
  });
};

// export const getJWTCookie = getCookie("accessToken");
// export const getUserIdCookie = getCookie("userId");
// export const getNameCookie = getCookie("name");
// export const getDealerCookie = getCookie("dealerName");
// export const getCountryCookie = getCookie("country");
// export const getUserType = getCookie("userType");
